import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LeadersTeam = () => {
  let { data } = queryResult
  let { strapiProfesionals: dataDirector } = queryDirectorResult
  return (
    <Layout>
      <SEO title="Equipo de liderazgo - ICDA" />
      <section>
        <div>
          <div className="w-100 d-flex bg-light inst-img">
            <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
              <h2 className="mb-4 fw-bold text-uppercase title">
                Institucional
              </h2>
              <h3>Equipo de liderazgo</h3>
            </div>
          </div>
          <div>
            <div className="container my-5 main">
              <div className="mb-5">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="shadow-sm bg-white professional-card rounded px-4 pt-4 pb-2 h-100 d-flex flex-column align-items-center">
                      <div
                        className="img-prof mb-3 bg-secondary"
                        style={{
                          backgroundImage: `${
                            dataDirector.profilePicture
                              ? `url(${dataDirector.profilePicture.url})`
                              : null
                          }`,
                        }}
                      ></div>
                      <h6 className="text-center">{dataDirector.name}</h6>
                      <h6 className="mb-3">
                        <small>{dataDirector.rol}</small>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {data.allStrapiProfesionals.edges.map(
                    ({ node: professional }) => (
                      <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <div className="shadow-sm bg-white professional-card rounded px-4 pt-4 pb-2 h-100 d-flex flex-column align-items-center">
                          <div
                            className="img-prof mb-3 bg-secondary"
                            style={{
                              backgroundImage: `${
                                professional.profilePicture
                                  ? `url(${professional.profilePicture.url})`
                                  : null
                              }`,
                            }}
                          ></div>
                          <h6 className="text-center">{professional.name}</h6>
                          <h6 className="mb-3">
                            <small>{professional.rol}</small>
                          </h6>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LeadersTeam

const queryDirectorResult = {
  strapiProfesionals: {
    name: "Lic. Gisela Veritier, MBA",
    description: "Descripcion de Lic. Gisela Veritier, MBA",
    profilePictureName: "Lic. Gisela Veritier, MBA",
    rol: "Directora General",
    profilePicture: {
      url:
        "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/VERITIER_cce0a5e7d5.jpg",
      name: "pexels-max-fischer-5212339.jpg",
    },
  },
}

const queryResult = {
  data: {
    allStrapiProfesionals: {
      edges: [
        {
          node: {
            name: "Lic. Gabriel Disiot, MBA",
            description: ".......",
            profilePictureName: "Lic. Gabriel Disiot, MBA",
            rol: "Director Académico",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/Captura_desde_2023_10_05_15_22_06_9a730bc1b7.png",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
        {
          node: {
            name: "Cecilia Beribey",
            description: "....",
            profilePictureName: "Cecilia Beribey",
            rol: "Directora de Relaciones Institucionales y Marketing",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/Cecilia_Beribey_29f681a621.jpeg",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
        {
          node: {
            name: "Lic. Andrea Mantegari",
            description: "....",
            profilePictureName: "Lic. Andrea Mantegari",
            rol: "Líder del área Desarrollos Corporativos. Capacitación In Company",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/Andrea_Mantegari_f359fb3867.jpeg",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
        // {
        //   node: {
        //     name: "Mgter. Verónica Navarro Alegre",
        //     description: "....",
        //     profilePictureName: "Lic. Gabriel Disiot, MBA",
        //     rol: "Directora de Relaciones Institucionales y Marketing",
        //     profilePicture: {
        //       url:
        //         "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/NAVARRO_ALEGRE_642db34508.jpg",
        //       name: "pexels-max-fischer-5212325.jpg",
        //     },
        //   },
        // },
        {
          node: {
            name: "Dr. Mariano Mosquera",
            description: "....",
            profilePictureName: "Dr. Mariano Mosquera",
            rol: "Director de la Maestría en Dirección de Empresas",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/MOSQUERA_186f2e072b.jpg",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
        {
          node: {
            name: "Dr. Eduardo Monferran",
            description: "....",
            profilePictureName: "Dr. Eduardo Monferran",
            rol:
              "Director de la Maestría en Derecho de Empresas",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/Monferran_d00fd005bb.png?4552969.3",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
        {
          node: {
            name: "Cr. Sebastián Tapia, MBA",
            description: "....",
            profilePictureName: "Cr. Sebastián Tapia, MBA",
            rol: "Director de la Especialización en Dirección de Finanzas",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/TAPIA_972912a1b6.jpg",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
        {
          node: {
            name: "Dra. Miriam Rojas",
            description: "....",
            profilePictureName: "Dra. Miriam Rojas",
            rol:
              "Directora de la Especialziación en Dirección Estratégica de Recursos Humanos",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/ROJAS_46dc66f4df.jpg",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
        {
          node: {
            name: "Mgter. César Murúa",
            description: "....",
            profilePictureName: "Mgter. César Murúa",
            rol:
              "Director de Especialización en Dirección de Organizaciones Públicas",
            profilePicture: {
              url:
                "https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/MURUA_7c6dd58e51.jpg",
              name: "pexels-max-fischer-5212325.jpg",
            },
          },
        },
      ],
    },
  },
}
